@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Abril Fatface';
  src: local('Abril Fatface'), url(/fonts/AbrilFatface-Regular.otf) format('otf');
}

@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(/fonts/Mulish-Regular.ttf) format('ttf');
}